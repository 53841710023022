export const R2_9_07_23_ReleaseSummary = [
  "Release Version: 32.10 PRODUCTION 1690833x32",
  "Summary: This release introduces Hyperlapse, a method of creating small, low framerate timelapsed videos of trips.",
  "With this release, the following firmware versions are considered DEPRECATED, are not recommended for use, and will no longer be supported by Xirgo except to update to the latest versions:",
  ["R32.7", "R32.7.1", "R32.8"],
];

export const R2_9_07_23_Features = [
  "Hyperlapse Videos.  See full documentation on portal.  [XIR2-1089] [XIR2-1397] [XIR2-1440] [XIR2-1441] [XIR2-1442]",
  "Several items are included in this release as part of code for OBD-PRO.  While OBD-PRO is not fully supported by this release, we are including our code changes early in order to reduce risk for partners and accelerate their testing when OBD-PRO is released. There is no partner action for this release note. [XIR2-1080]",
];

export const R2_9_07_23_CompatibilityIssues = [];

export const R2_9_07_23_Improvements = [
  "Updated ADAS Library to newest version for increased performance [XIR2-903]",
];

export const R2_9_07_23_IssuesAddressed = [
  "Fixed an issue that could cause a camera to enter a reboot loop in rare situations [XIR2-1472]",
  "Fixed an issue which could cause a failed bootup with only a black screen in rare situations. [XIR2-1474]",
  "Fixed an issue which could cause devices to show a reduced video storage capacity [XIR2-1474]",
];

export const R2_9_07_23_Errata = [
  "Devices not mounted in a vehicle may trigger aggressive driving events on startup.",
  "A small subset of devices cannot update firmware on serial communication chip; they will need to be replaced to use OBD-PRO Cable upon its delivery and will have corrupted DTCs if >10 DTCs are present. (No particular firmware)",
  "When Hyperlapse is enabled, /trips/camera API  will report hyperlapseVideo: false until a hyperlapse video is uploaded, then switch to hyperlapseVideo: true",
];

export const R2_9_07_23_KnownIssues = [
  "ADAS/DMS",
  [
    "ADAS features will be disabled during live view sessions. (R32.3) [XIR2-236]",
    "Forward Collision Warning is overly sensitive even on Low settings.  (R32.6) [XIR2-857]",
  ],
  "Camera Recording",
  [
    "If devices are without network for several months, they may fill up their storage with media clips and be unable to record new video until all media is uploaded. (All version) [XIR2-1110]",
    "Occasionally, OK Presto voice recognition is disabled when device has recently regained network connection (R32.5) [XIR2-761]",
    "Occasionally, if the camera is live viewed while it is in guard mode, audio will cut out after approximately 20 seconds.  This affects Live View only and does not affect history/event audio. (R32.9) [XIR2-1358]",
  ],
  "Guard Mode",
  [
    "Extremely rarely, the device will not properly enter guard mode at the end of the timer. Bumps, trips, or other events will reset this and normal function will resume. (All versions) [XIR-700]",
    "Extremely rarely, the device will not enter guard mode at the end of the timer if “guard mode ignitionOnDisable” is enabled. (R32.9) [XIR2-1360]",
  ],
  "LCD Display",
  [
    "Occasionally video on display has slight lag. (All versions) [XIR2-229]",
    "Occasionally, sliding 'up' on the display will not turn on the inside camera and the 'outside' camera view will be stretched poorly.  Fix is to swipe down, then up again. (All versions) [XIR2-219]",
    "Occasionally, red 'Recording' dot on LCD display will be there indefinitely after an 'OK Presto' clip is made. (All versions) [XIR2-246]",
    "A slight lag is noticed in switching from Night to Day mode on the LCD display (R32.5) [XIR-578]",
    "When displayRequiresIgnition is enabled, it is possible display will not come on during an Ignition On event (R32.7) [XIR2-884]",
    "Swiping up and down on the display in rapid succession will cause the “Inside Camera” setting to become out of sync with what is reflected in the cloud (All versions) [XIR2-1108]",
    "Occasionally, changing orientation from the LCD Settings menu during a trip will cause the LCD screen to not turn on.  Resolved by rebooting device (hold down button on side for 5 seconds) (R32.9) [XIR2-1322]",
  ],
  "Telemetry",
  [
    "Occasionally, event files will not be uploaded until the device’s next scheduled reboot (Fleet only) (R32.5) [XIR2-924]",
    "History clips will include the current telemetry information instead of the telemetry of the requested timestamp (R32.5) [XIR2-252]",
  ],
  "Watermarking",
  [
    "When changing watermarking settings, the size of the watermark will be very large during any video clip that includes the exact time the setting was changed. (R32.9) [XIR2-1106]",
    "Timestamps on watermarked videos will sometimes jump by 2 seconds at a time. (R32.9) [XIR2-1375]",
  ],
  "Other",
  [
    "Audio setting takes some time to take effect (< 30 seconds). (All versions) [XIR2-224]",
  ],
];
