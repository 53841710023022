import React from "react";

export default function XirgocamLogo(props) {
  return (
    <svg
      height={props.height}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 174 62"
      style={{ margin: 3 }}
    >
      <defs>
        <style>
          {`
              .st0 {
                fill: none;
              }
              .st1 {
                fill: #fff;
              }
              .st2 {
                fill: #44d62c;
              }
              .st3 {
                fill: #90e782;
              }
            `}
        </style>
      </defs>
      <rect className="st0" x="-.05" width="172.88" height="60.79" />
      <g>
        <polygon
          className="st1"
          points="37.65 20.51 32.9 20.51 26.86 26.46 20.73 20.51 15.76 20.51 24.32 28.77 15.13 37.67 19.88 37.67 26.57 31.09 33.33 37.67 38.38 37.67 29.09 28.76 37.65 20.51"
        />
        <rect className="st1" x="40.24" y="20.51" width="3.58" height="17.16" />
        <path
          className="st1"
          d="M65.93,26.86c0-3.92-2.42-6.35-6.33-6.35h-12.17v17.16h3.55v-13.59h8.62c1.86,0,2.8.95,2.8,2.82s-.94,2.96-2.8,2.96h-7.21v3.24h6.35l4.17,4.57h5.07l-5.19-5.24c2-.98,3.14-2.98,3.14-5.58h0Z"
        />
        <path
          className="st1"
          d="M76.13,30.52h7.43v3.58h-6.69c-2.76,0-4.77-2.12-4.77-5.04s2-4.97,4.77-4.97h9.27v-3.57h-9.27c-4.91,0-8.34,3.46-8.34,8.41s3.59,8.75,8.34,8.75h10.29v-10.28h-11.03v3.12Z"
        />
        <path
          className="st1"
          d="M102.1,20.51h-4.01c-4.91,0-8.34,3.46-8.34,8.41s3.59,8.75,8.34,8.75h4.01c4.8,0,8.41-3.76,8.41-8.75s-3.46-8.41-8.41-8.41ZM102.1,34.1h-4.01c-2.76,0-4.77-2.12-4.77-5.04s2-4.97,4.77-4.97h4.01c2.85,0,4.84,2.04,4.84,4.97s-2.03,5.04-4.84,5.04Z"
        />
      </g>
      <g>
        <polygon
          className="st3"
          points="139.14 16.85 133.84 21.86 129.14 16.85 123.39 16.85 133.49 27.63 144.89 16.85 139.14 16.85"
        />
        <polygon
          className="st3"
          points="133.31 30.61 120.39 42.83 126.14 42.83 132.96 36.38 139 42.83 144.75 42.83 133.31 30.61"
        />
        <polygon
          className="st3"
          points="161.26 9.6 155.51 9.6 134.88 29.12 147.71 42.83 153.46 42.83 140.63 29.12 161.26 9.6"
        />
        <polygon
          className="st2"
          points="120.43 16.85 114.68 16.85 126.17 29.12 106.35 47.88 112.1 47.88 131.92 29.12 120.43 16.85"
        />
      </g>
    </svg>
  );
}
