export const R2_5_24_23_ReleaseSummary = [
  "Release Version: 32.9 PRODUCTION 1684433x32",
  "This release is targeted at addressing issues for partners including irregular video recording, device telemetry issues, device crashes, and general stability.  It also introduces Watermarked Video as a major feature.",
  "With this release, the following firmware versions are considered DEPRECATED, are not recommended for use, and will no longer be supported by Xirgo except to update to the latest versions:",
  [
    "All R32.9 ALPHA versions",
    "R32.7.5",
    "R32.7.4.1",
    "R32.7.2",
    "All releases prior to R32.6.3",
  ],
];

export const R2_5_24_23_Features = [
  "Added ability for partners to enable/disable an overlay with Date, Time, Location, and Speed on both recorded video and the LCD screen (Watermark Video) [XIR2-87] [XIR2-1020]",
  "Added setting that partners can enable to reduce GPS Drift when device is stationary (GPS Pinning).",
  "Added setting to force CAN discovery on vehicles whose bus may not be active on the OBDII port by default. This setting overrides a number of safeties and is used only as an override on specific vehicles, not a blanket setting. USE AT YOUR OWN RISK! [XIR2-244]",
  "Added support for secondary MVNO SIMs [XIR2-367] [XIR2-368]",
  "Introduced SysMon, a microservice that monitors other device microservices.  [XIR2-33] [XIR2-1139] [XIR2-1182] [XIR2-1184] [XIR2-1185] [XIR2-1186] [XIR2-1238] [XIR2-1259]",
  "Implemented a separate hardware watchdog that monitors communications between the main chip and serial/power control chip and resets the device if communications stop [XIR2-1282] [XIR2-1300]",
  "In the case of total main processor failure, the device will reboot itself within 12 hours. [XIR2-1293]]",
];

export const R2_5_24_23_CompatibilityIssues = [];

export const R2_5_24_23_Improvements = [
  "Updated ADAS Library to latest version [XIR2-179]",
  "Updated DMS Library to latest version [XIR2-928]",
  "OTA Process is now more robust and reliable in case of main application failure [XIR2-29]",
  "Further optimized power consumption while in guard mode [XIR2-947]",
  "Expanded error logging (Xirgo Internal Only) [XIR2-67] [XIR2-778] [XIR2-943] [XIR2-1168] [XIR2-1114] [XIR2-1132] [XIR2-1134] [XIR2-1135] [XIR2-1171] [XIR2-1177] [XIR2-1219] [XIR2-1220] [XIR2-1221] [XIR2-1232] [XIR2-1271] [XIR2-1288] [XIR2-1307]",
  "Improved DMS auto-detection of Region of Interest for all DMS Events [XIR2-325]",
  "DMS processing is further optimized, using less device resources [XIR2-1129]",
  "Significant code cleanup to allow higher quality firmware releases [XIR2-1145] [XIR2-1156]",
];

export const R2_5_24_23_Bugfixes = [
  "Addressed several stability issues that could cause black screens on boot, device crashes while entering guard mode, device crashes while exiting guard mode, and high current draw in guard mode.  [XIR2-1117] [XIR2-216] [XIR2-778] [XIR2-942] [XIR2-1122] [XIR2-1123] [XIR2-1124] [XIR2-1131] [XIR2-1144] [XIR2-1146] [XIR2-1147] [XIR2-1150] [XIR2-1152] [XIR2-1162] [XIR2-1166] [XIR2-1172] [XIR2-1217] [XIR2-1239] [XIR2-1250] [XIR2-1290] [XIR2-1295]",
  "Addressed several issues that could cause loss of telemetry during a trip, device crashes during a trip, device freezing.  [XIR2-7] [XIR2-1011] [XIR2-1038] [XIR2-1081] [XIR2-1092] [XIR2-1109] [XIR2-1113] [XIR2-1133] [XIR2-1148] [XIR2-1157] [XIR2-1167] [XIR2-1181] [XIR2-1260]",
  "Addressed several issues that could cause devices to enter a boot loop [XIR2-1100] [XIR2-1117] [XIR2-1127]",
  "Fixed an issue that would occasionally cause the device to not complete boot after an inactivate/activate cycle [XIR2-1374]",
  "Fixed an issue which would cause audio in history and events to be disabled no matter the setting. [XIR2-1373]",
  "Timestamps across the device now use all available sources, reducing issues where the journal is incorrect and/or video retrieval requests fail [XIR2-106] [XIR2-181] [XIR2-907] [XIR2-908]  [XIR2-911] [XIR2-1016] [XIR2-1069] [XIR2-1302] [XIR2-1317]",
  "GPS telemetry retrieval is centralized and the same location retrieval is used for all webhooks and events [XIR2-212]",
  "Fixed issue which could cause devices to enter guard mode while driving [XIR2-259]",
  "Removed “Battery Credits” system entirely. This legacy system previously could cause units to enter Low Power Mode (become unresponsive) unexpectedly. [XIR2-950]",
  "Fixed an issue that could cause devices to not accept settings immediately after activation [XIR2-1234]",
  "Fixed an issue that could cause devices to not send telemetry immediately after activation [XIR2-97]",
  "Fixed an issue that would cause DMS: cell phone events whose upload was interrupted by a loss of power to repeatedly send [XIR2-1082]",
  "DMS: cell phone events are no longer detected while vehicle speed is 0 [XIR2-893]",
  "In trips interrupted by a reboot, the trip file (JSON file) will no longer contain extraneous data after the tripEnd message [XIR2-902]",
  "DMS Cell Phone Events now upload the correct number of thumbnails (1) [XIR2-922]",
  "DMS Cell Phone events will no longer upload “phantom” events in case of a power interruption during upload [XIR2-1082]",
  "A device will no longer crash entirely when it runs out of storage space after being out of network for several months [XIR2-53]",
  "DMS events now upload accelerometer or gps csv files [XIR2-919]",
];

export const R2_5_24_23_KnownIssues = [
  "ADAS/DMS",
  [
    "ADAS features will be disabled during live view sessions. (R32.3) [XIR2-236]",
    "DMS cell-phone-raw.json file is not parsed correctly and does not include proper JSON formating (R32.7) [XIR2-791]",
    "Occasional frame rate drops may be noticed on the LCD when DMS is enabled.  This does not affect recorded video. [XIR2-944]",
    "Forward Collision Warning is overly sensitive even on Low settings.  (R32.6) [XIR2-857]",
  ],
  "Camera Recording",
  [
    "If devices are without network for several months, they may fill up their storage with media clips and be unable to record new video until all media is uploaded. (All version) [XIR2-1110]",
    "Occasionally, OK Presto voice recognition is disabled when device has recently regained network connection (R32.5) [XIR2-761]",
    "Occasionally, if the camera is live viewed while it is in guard mode, audio will cut out after approximately 20 seconds.  This affects Live View only and does not affect history/event audio. (R32.9) [XIR2-1358]",
  ],
  "Guard Mode",
  [
    "Extremely rarely, the device will not properly enter guard mode at the end of the timer.  Bumps, trips, or other events will reset this and normal function will resume. (All versions) [XIR-700]",
    "Extremely rarely, the device will not enter guard mode at the end of the timer if “guard mode ignitionOnDisable” is enabled. (R32.9) [XIR2-1360]",
  ],
  "LCD Display",
  [
    "Occasionally video on display has slight lag. (All versions) [XIR2-229]",
    "Occasionally, sliding 'up' on the display will not turn on the inside camera and the 'outside' camera view will be stretched poorly.  Fix is to swipe down, then up again. (All versions) [XIR2-219]",
    "Occasionally, red 'Recording' dot on LCD display will be there indefinitely after an 'OK Presto' clip is made. (All versions) [XIR2-246]",
    "A slight lag is noticed in switching from Night to Day mode on the LCD display (R32.5) [XIR-578]",
    "When displayRequiresIgnition is enabled, it is possible display will not come on during an Ignition On event (R32.7) [XIR2-884]",
    "Swiping up and down on the display in rapid succession will cause the “Inside Camera” setting to become out of sync with what is reflected in the cloud (All versions) [XIR2-1108]",
    "Occasionally, changing orientation from the LCD Settings menu during a trip will cause the LCD screen to not turn on.  Resolved by rebooting device (hold down button on side for 5 seconds) (R32.9) [XIR2-1322]",
  ],
  "Telemetry",
  [
    "Occasionally, event files will not be uploaded until the device’s next scheduled reboot (Fleet only) (R32.5) [XIR2-924]",
    "History clips will include the current telemetry information instead of the telemetry of the requested timestamp (R32.5) [XIR2-252]",
  ],
  "Watermarking",
  [
    "When changing watermarking settings, the size of the watermark will be very large during any video clip that includes the exact time the setting was changed. (R32.9) [XIR2-1106]",
    "Timestamps on watermarked videos will sometimes jump by 2 seconds at a time. (R32.9) [XIR2-1375]",
  ],
  "Other",
  [
    "Audio setting takes some time to take effect (< 30 seconds). (All versions) [XIR2-224]",
    "Devices not mounted in a vehicle may trigger aggressive driving events on startup. (R32.3) [XIR2-230]",
    "A small subset of devices cannot update firmware on serial communication chip; they will need to be replaced to use OBD-PRO Cable upon its delivery and will have corrupted DTCs if >10 DTCs are present. (No particular firmware) [XIR-730]",
  ],
];
